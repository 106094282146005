<template>
  <a-modal :visible="visible" width="80%" :maskClosable="false" title="志工排班" :footer="null" @cancel="handleClose">
    <div class="content-box flex-box">
      <template v-if="detail">
        <div class="table-data">
          <a-space size="large">
            <span>设置排班</span>
            <a-button type="primary" @click="handleSave">保存设置</a-button>
            <a-button type="primary" @click="sendNotice">发送通知</a-button>
          </a-space>
          <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th class="line-th">
                  <span class="classes-title">班次</span>
                  <span class="post-title">岗位</span>
                </th>
                <th
                  v-for="(post, pIndex) in detail.servePost"
                  :key="pIndex"
                  @drop="drop($event, pIndex, null)"
                  @dragover="dragover"
                >
                  <div>{{ post.name }}</div>
                  <a-tag :visible="true" :closable="!!post.leader" @close="delTag(post)">
                    负责人：{{ (post.leader && post.leader.name) || "无" }}
                  </a-tag>
                </th>
              </tr>
              <tr v-for="(classes, cIndex) in detail.serveClasses" :key="classes.id">
                <td>
                  <div>{{ classes.name }}</div>
                  <div class="classes-time">{{ classes.start_time }}-{{ classes.end_time }}</div>
                </td>
                <template v-if="cIndex == 0">
                  <td
                    v-for="(post, pIndex) in detail.servePost"
                    :key="pIndex"
                    :rowspan="post.type ? detail.serveClasses.length : 1"
                    @drop="drop($event, pIndex, cIndex)"
                    @dragover="dragover"
                  >
                    <a-tag
                      v-for="(member, mIndex) in getTeamMembers(post, cIndex)"
                      :key="member.id"
                      closable
                      @close="delTag(post, cIndex, mIndex)"
                    >
                      {{ member.name }}
                    </a-tag>
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="(post, pIndex) in detail.servePost.slice(0, detail.servePost.length - 1)"
                    :key="pIndex"
                    @drop="drop($event, pIndex, cIndex)"
                    @dragover="dragover"
                  >
                    <a-tag
                      v-for="(member, mIndex) in post.serveClasses[cIndex].teamMembers"
                      :key="member.id"
                      closable
                      @close="delTag(post, cIndex, mIndex)"
                    >
                      {{ member.name }}
                    </a-tag>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="data-list flex-box">
          <div>已通过审核志工</div>
          <div class="list-title flex-box align-center justify-between">
            <span>共{{ pagination.total }}人</span>
            <a-button @click="updateList">查询</a-button>
          </div>
          <div class="form-box">
            <a-form-model class="query-form" layout="inline">
              <a-form-model-item>
                <a-select
                  style="width: 130px;"
                  placeholder="是否超时报名"
                  @change="updateList"
                  :options="flagOptions"
                  v-model="form.is_timeout"
                  allowClear
                >
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select
                  style="width: 130px;"
                  placeholder="可服务班次"
                  @change="updateList"
                  :options="classesTypeOptions"
                  v-model="form.classes"
                  allowClear
                >
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-input v-model="form.name" placeholder="输入姓名" allowClear style="width: 120px;"></a-input>
              </a-form-model-item>
            </a-form-model>
          </div>
          <a-spin :spinning="loading">
            <div class="member-list" v-if="list && list.length > 0">
              <div
                class="member-item"
                v-for="(member, index) in list"
                :key="member.id"
                draggable
                @dragstart="dragstart($event, index)"
              >
                <div class="flex-box align-center justify-between">
                  <div class="flex-box align-center" @click="memberDetail(member)">
                    <span>{{ member.volunteer.name }}</span>
                    <a-icon type="right" />
                  </div>
                  <a-tag :color="member.postName.length > 0 ? 'orange' : 'grey'">
                    {{ member.postName.length > 0 ? "已排班" : "未排班" }}
                  </a-tag>
                </div>
                <a-descriptions :column="2" size="small" class="descriptions">
                  <a-descriptions-item label="已安排岗位">
                    <template v-if="member.postName && member.postName.length > 0">
                      <a-tag v-for="(post, pIndex) in member.postName" :key="pIndex">{{ post.post_name }}</a-tag>
                    </template>
                    <span v-else>无</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="是否超时报名">
                    {{ member.timeoutText }}
                  </a-descriptions-item>
                  <a-descriptions-item label="就餐报备">
                    <template v-if="member.meals.length > 0">
                      <a-tag v-for="(meal, mIndex) in member.meals" :key="mIndex">{{ meal }}</a-tag>
                    </template>
                    <span v-else>-</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="可服务班次">
                    <a-tag v-for="(classes, cIndex) in member.classesList" :key="cIndex">{{ classes }}</a-tag>
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
            <a-empty v-else />
          </a-spin>
        </div>
      </template>
    </div>
    <a-drawer title="人员详情" placement="right" width="400" :visible="dVisible" @close="onClose">
      <a-descriptions :column="1" v-if="member">
        <a-descriptions-item label="志工姓名">
          {{ member.volunteer.name }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ member.volunteer.id_card }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号码">
          {{ member.volunteer.tel }}
        </a-descriptions-item>
        <a-descriptions-item label="志工状态">
          {{ member.volunteer.statusText }}
        </a-descriptions-item>
        <a-descriptions-item label="是否设置排班">
          {{ member.postName.length > 0 ? "是" : "否" }}
        </a-descriptions-item>
        <a-descriptions-item label="是否超时报名">
          {{ member.timeoutText }}
        </a-descriptions-item>
        <a-descriptions-item label="就餐报备">
          <a-tag v-for="(meal, mIndex) in member.meals" :key="mIndex">{{ meal }}</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="车牌报备">
          {{ member.carText }}
        </a-descriptions-item>
        <a-descriptions-item label="可服务班次">
          <a-tag v-for="(classes, cIndex) in member.classesList" :key="cIndex">{{ classes }}</a-tag>
        </a-descriptions-item>
      </a-descriptions>
    </a-drawer>
  </a-modal>
</template>

<script>
import {
  classesTypeOptions,
  dinnerReportOptions,
  flagOptions,
  volunStatusOptions,
} from "../../../../common/hr/volunteer";
import { getTemple } from "../../../../common/js/storage";
import { clone, getKeyTitle, isEmpty } from "../../../../common/js/tool";
export default {
  name: "ClassesModal",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      value: false,
    },
    info: Object,
  },
  data() {
    return {
      searchKeyType: {
        // is_timeout: 2,
        classes: 2,
        name: 2,
      },
      loading: false,
      list: [],
      form: {},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 9999,
        showSizeChanger: true,
      },
      flagOptions,
      classesTypeOptions,
      getKeyTitle,
      detail: null,
      dVisible: false,
      member: null,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  watch: {
    info() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.getDetail();
      this.getList();
    },
    getDetail() {
      this.$axios({
        url: "/admin/volunteer-schedule",
        method: "GET",
        params: {
          "filter[volunteer_service_id]": this.info?.id,
          sort: "-id",
          expand: "servePost,serveClasses,volunteer,volunteerService,head",
        },
      }).then((res) => {
        let data = res.data;
        let detail = clone(this.info);
        detail.serveClasses?.forEach((item) => {
          item.name = getKeyTitle(classesTypeOptions, item.type, "value", "label");
        });
        let postList = data.filter((item) => !item.type);
        // 初始化成员
        detail.servePost.forEach((post) => {
          post.type = 0; // 默认不是机动岗
          post.leader = null; // 负责人
          post.serveClasses = clone(detail.serveClasses) || [];
          post.serveClasses.forEach((classes) => {
            classes.teamMembers = []; // 岗位成员
            if (postList.length > 0) {
              // 设置岗位成员
              let pcTarget = postList.find(
                (item) => item.serve_post_id == post.id && item.serve_classes_id == classes.id
              );
              if (pcTarget) {
                classes.schedule_id = pcTarget.id;
                classes.teamMembers = pcTarget.volunteer;
              }
            }
          });
          if (postList.length > 0) {
            // 设置岗位和负责人
            let postTarget = postList.find((item) => item.serve_post_id == post.id);
            if (postTarget) {
              post.type = postTarget.type;
              post.leader = postTarget.head;
            }
          }
        });
        let mobilePost = { id: "mobile_post_id", name: "机动岗", type: 1, leader: null, teamMembers: [] };
        detail.servePost.push(mobilePost);
        if (data.length > 0) {
          // 设置机动岗
          let target = data.find((item) => item.type == 1);
          if (target) {
            mobilePost.schedule_id = target.id;
            mobilePost.teamMembers = target.volunteer;
            mobilePost.leader = target.head;
          }
        }
        this.detail = detail;
      });
    },
    getList() {
      this.loading = true;
      let url = `/admin/volunteer-enroll-list?sort=-id&expand=volunteer,postName&status=2&filter[type]=3&filter[content_id]=${this.info?.id}`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios
        .get(url, { noTempleFilter: true })
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dealList(list) {
      list.forEach((item) => {
        item.timeoutText = getKeyTitle(flagOptions, item.is_timeout, "value", "label");
        let meals = [];
        item.dinner_report?.split(",").forEach((meal) => {
          let text = getKeyTitle(dinnerReportOptions, meal, "value", "label");
          meals.push(text);
        });
        item.meals = meals;
        let classesList = [];
        item.serviceClass.forEach((classes) => {
          let text = getKeyTitle(classesTypeOptions, classes.type, "value", "label");
          classesList.push(text);
        });
        item.classesList = classesList;
        item.volunteer.statusText = getKeyTitle(volunStatusOptions, item.volunteer.register_status, "value", "label");
        if (item.is_car == 1) {
          item.carText = item.numberplate;
        } else {
          item.carText = "否";
        }
        item.postName = item.postName || [];
      });
      return list;
    },
    getQuery(keyType) {
      let query = "";
      let form = this.form;
      let searchKeyType = keyType || this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            query += `&filter[${key}]=${form[key]}`;
          }
        }
      }
      return query;
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    // 开始拖拽
    dragstart(e, index) {
      e.dataTransfer.setData("text/plain", index);
    },
    dragover(e) {
      e.preventDefault();
    },
    // 结束拖拽
    drop(e, pIndex, cIndex) {
      e.preventDefault();
      let eIndex = e.dataTransfer.getData("text/plain");
      let enroll = this.list[eIndex];
      let member = enroll.volunteer;
      let post = this.detail.servePost[pIndex];
      if (isEmpty(cIndex)) {
        // 不存在班次，则为负责人
        post.leader = member;
        this.addEnrollPostInfo(enroll, post);
      } else {
        // 岗位成员
        let teamMembers = [];
        if (post.type) {
          // 机动岗
          teamMembers = post.teamMembers;
        } else {
          teamMembers = post.serveClasses[cIndex].teamMembers;
        }
        let tIndex = teamMembers.findIndex((item) => item.id == member.id);
        if (tIndex > -1) {
          this.$message.warning("该成员已存在");
        } else if (this.isMoreLimit(post)) {
          this.$message.warning("已超出该岗位限制名额");
        } else if (!post.type && !this.canServe(post.serveClasses[cIndex], enroll)) {
          // 非机动岗且不符合条件
          this.$message.warning("该人员不符合服务该班次");
        } else {
          teamMembers.push(member);
          this.addEnrollPostInfo(enroll, post);
        }
      }
    },
    // 判断是否有名额限制且超出该限制
    isMoreLimit(post) {
      let flag = false;
      if (post.is_limit == 2) {
        // 岗位限定名额
        let total = post.serveClasses?.reduce((acc, cur) => acc + cur.teamMembers.length, 0);
        if (total >= post.num) {
          flag = true;
        }
      }
      return flag;
    },
    // 判断能否服务该班次
    canServe(classes, enroll) {
      return enroll.classes
        ?.split(",")
        .map(Number)
        .includes(classes.id);
    },
    getTeamMembers(post, cIndex) {
      return post.type ? post.teamMembers : post.serveClasses[cIndex].teamMembers;
    },
    // 删除排班表上的人员
    delTag(post, cIndex, mIndex) {
      if (!isEmpty(cIndex) && !isEmpty(mIndex)) {
        // 岗位成员
        let teamMembers = this.getTeamMembers(post, cIndex);
        let member = teamMembers.splice(mIndex, 1)[0];
        this.delEnrollPostInfo({ member, post, cIndex, type: "member" });
      } else {
        // 负责人
        let member = clone(post.leader);
        post.leader = null;
        this.delEnrollPostInfo({ member, post, cIndex, type: "leader" });
      }
    },
    // 添加已安排岗位信息
    addEnrollPostInfo(enroll, post) {
      let hasPost = enroll.postName.some((item) => item.post_id == post.id);
      if (!hasPost) {
        enroll.postName.push({ post_name: post.name, post_id: post.id });
      }
    },
    // 删除已安排岗位信息
    delEnrollPostInfo({ member, post, cIndex, type }) {
      let flag = true;
      if (post.type) {
        // 机动岗
        let teamMembers = post.teamMembers;
        let hasMember = teamMembers.some((item) => item.id == member.id);
        if (hasMember) {
          flag = false;
        }
      } else {
        for (let i = 0; i < post.serveClasses.length; i++) {
          if (i === cIndex) {
            // 当前班次不判断
            continue;
          }
          let teamMembers = post.serveClasses[i].teamMembers;
          let hasMember = teamMembers.some((item) => item.id == member.id);
          if (hasMember) {
            flag = false;
            break;
          }
        }
      }
      if (type == "member") {
        // 成员被删，还需判断负责人
        if (post.leader && post.leader.id == member.id) {
          flag = false;
        }
      }
      if (flag) {
        this.delPostName(post, member);
      }
    },
    delPostName(post, member) {
      let target = this.list.find((item) => item.volunteer_id == member.id);
      let postName = target.postName;
      let index = postName.findIndex((item) => item.post_id == post.id);
      postName.splice(index, 1);
    },
    handleSave() {
      let servePost = this.detail.servePost;
      let temple = getTemple();
      let scheduleList = servePost.reduce((acc, cur) => {
        if (cur.type) {
          // 机动岗
          acc.push({
            temple_id: temple,
            id: cur.schedule_id,
            volunteer_id: cur.teamMembers.map((member) => member.id).join(",") || null, // 不存在，则赋值为null，防止序列化时丢失该字段
            volunteer_service_id: this.detail.id,
            type: cur.type,
            head_id: cur.leader?.id || null, // 不存在，则赋值为null，防止序列化时丢失该字段
          });
        } else {
          cur.serveClasses.forEach((classes) => {
            acc.push({
              temple_id: temple,
              id: classes.schedule_id,
              serve_post_id: cur.id,
              serve_classes_id: classes.id,
              volunteer_id: classes.teamMembers.map((member) => member.id).join(",") || null, // 不存在，则赋值为null，防止序列化时丢失该字段
              volunteer_service_id: this.detail.id,
              type: cur.type,
              head_id: cur.leader?.id || null, // 不存在，则赋值为null，防止序列化时丢失该字段
            });
          });
        }
        return acc;
      }, []);
      // console.log("handleSave:", scheduleList, JSON.stringify(scheduleList));
      this.$confirm({
        title: "提示",
        content: `确定保存排班吗？`,
        onOk: () => {
          this.$axios({
            url: "/admin/volunteer-schedule/add",
            method: "POST",
            data: {
              scheduleList: JSON.stringify(scheduleList),
            },
          }).then((res) => {
            if (res.error == 0) {
              this.$message.success("保存成功");
            } else {
              this.$message.error(res.msg);
            }
          });
        },
      });
    },
    sendNotice() {
      this.$confirm({
        title: "提示",
        content: `发送通知短信后，无法再修改班次与岗位，只可变动人员，是否确认发送？`,
        onOk: () => {
          this.$axios({
            url: "/admin/volunteer-schedule/send-msg",
            method: "POST",
            data: {
              volunteer_service_id: this.info.id,
            },
          }).then((res) => {
            if (res.error == 0) {
              this.$message.success("短信发送成功");
            } else {
              this.$message.error(res.msg);
            }
          });
        },
      });
    },
    handleClose() {
      this.$confirm({
        title: "提示",
        content: `离开前记得保存设置哦`,
        onOk: () => {
          this.visible = false;
        },
      });
    },
    memberDetail(member) {
      this.member = member;
      this.dVisible = true;
    },
    onClose() {
      this.dVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  min-height: 600px;
}
.line-th {
  background: #fff
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEiLz48L3N2Zz4=)
    no-repeat 100% center;
}
.classes-time {
  white-space: nowrap;
}
.table-data {
  flex: 7;
}
.data-list {
  flex: 3;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
  tr {
    th,
    td {
      text-align: center;
      padding: 8px;
    }
  }
}
.classes-title {
  float: left;
  margin-top: 20px;
}
.post-title {
  float: right;
}

.leader {
  margin-top: 8px;
}

.form-box {
  margin-bottom: 8px;
}
.member-list {
  flex: 1;
  overflow: auto;
  min-height: 0;
  margin-top: 8px;
}
.member-item {
  padding: 6px;
  border: 1px solid grey;
  border-radius: 4px;
  cursor: move;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.descriptions {
  margin-top: 12px;
}
</style>
