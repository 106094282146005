<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      query="&sort=-id&expand=serviceType,volunteerGroup,enrollNum,serveClasses,servePost"
      entity-name="服务"
      show-row-num
      hide-show-detail
      hide-edit
      hide-delete
      add-btn="添加服务"
      :columns="columns"
      :search-key-type="searchKeyType"
      :add-route="addRoute"
      :deal-list="dealList"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入服务名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择服务类型查询"
            @change="updateList"
            :options="typeOptions"
            v-model="form.service_type_id"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="showDetail(item)" v-action:query>查看</a>
          <a class="txt-btn" @click.stop="editRow(item)" v-action:update v-if="item.status < 3">编辑</a>
          <a class="txt-btn" @click.stop="copyRow(item)">复制</a>
          <a
            class="txt-btn danger"
            @click.stop="delRow(item)"
            v-action:delete
            v-if="item.status == 1 || item.status == 4"
          >
            删除
          </a>
          <a class="txt-btn" @click.stop="showEnroll(item)">报名名单</a>
          <a class="txt-btn" @click.stop="classesEnroll(item)">志工排班</a>
        </div>
      </template>
    </data-list>
    <EnrollModal v-if="eVisible" v-model="eVisible" :info="service" :type="3"></EnrollModal>
    <ClassesModal v-if="cVisible" v-model="cVisible" :info="service"></ClassesModal>
  </div>
</template>

<script>
import { dataConvertOptions, timestampConvertString } from "../../../common/js/tool";
import EnrollModal from "./components/EnrollModal.vue";
import ClassesModal from "./components/ClassesModal.vue";
import { getServiceStatus } from '../../../common/hr/volunteer';

export default {
  name: "ServiceList",
  components: {
    EnrollModal,
    ClassesModal,
  },
  data() {
    return {
      url: "/admin/volunteer-service",
      searchKeyType: {
        name: 1,
        title: 1,
      },
      columns: [
        { title: "服务名称", dataIndex: "name" },
        {
          title: "服务类型",
          customRender: (text) => {
            return <span>{text.serviceType?.name || "-"}</span>;
          },
        },
        {
          title: "服务地点",
          customRender: (text) => {
            let { location, address } = text;
            location = location.replace(/,/g, "");
            return <span>{location + address}</span>;
          },
        },
        {
          title: "服务时间",
          customRender: (text) => {
            let { service_date, start_time, end_time } = text;
            return <span>{`${timestampConvertString(service_date, "yyyy-MM-dd")} ${start_time}-${end_time}`}</span>;
          },
        },
        {
          title: "值班小组",
          customRender: (text) => {
            return <span>{text.volunteerGroup?.name || "-"}</span>;
          },
        },

        { title: "招募总人数", dataIndex: "num" },
        { title: "报名人数", dataIndex: "enrollNum" },
        { title: "状态", dataIndex: "statusText" },
      ],
      typeOptions: [],
      addRoute: "ServiceAdd",
      service: null,
      eVisible: false,
      cVisible: false,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      return this.$axios({
        url: "/admin/volunteer-service-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.typeOptions = dataConvertOptions(res.data);
      });
    },
    // 获取前一天12点
    getYesterdayTwelve(currentDate) {
      // 获取当前时间对象
      currentDate = new Date(currentDate);
      // 将时间设置为前一天
      currentDate.setDate(currentDate.getDate() - 1);
      // 将时间设置为12点
      currentDate.setHours(12, 0, 0, 0);
      // 获取时间戳
      const timestamp = currentDate.getTime();
      return new Date(timestamp).pattern("yyyy-MM-dd HH:mm");
    },
    dealList(list, fn) {
      list?.forEach((item) => {
        getServiceStatus(item);
      });
      fn(list);
    },
    updateList() {
      this.dataList.updateList();
    },
    showDetail(item) {
      this.$router.push({
        name: "ServiceDetail",
        params: { id: item.id },
      });
    },
    editRow(item) {
      this.$router.push({
        name: "ServiceDetail",
        params: { id: item.id },
        query: { edit: 1 },
      });
    },
    copyRow(item) {
      this.$router.push({
        name: "ServiceAdd",
        query: { id: item.id },
      });
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此志工服务吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
    showEnroll(item) {
      this.service = item;
      this.eVisible = true;
    },
    classesEnroll(item) {
      this.service = item;
      this.cVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.status-1 {
  color: @error-color;
}
.status-2 {
  color: @success-color;
}
.ant-calendar-picker {
  text-align: left;
}
</style>
